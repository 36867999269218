

import UploadAvatar from './src/UploadAvatar.vue'
import { App } from 'vue'


UploadAvatar.install = (app: App) => {
    app.component(UploadAvatar.name, UploadAvatar)
}

export default UploadAvatar
