import Cropper from 'cropperjs'

import 'cropperjs/dist/cropper.min.css'

import { computed, nextTick, onMounted, ref, watch } from "vue";

const useUpload = (props: any, ctx: any) => {


    const img = ref<HTMLImageElement | null>(null);
    const base64 = ref('');
    const originSrc = ref('');
    const isShowCrop = ref(false);
    const nativeInputValue = computed(() => {
        return props.modelValue === null || props.modelValue === undefined
            ? ""
            : String(props.modelValue);
    });
    const cropper = ref<Cropper | null>(null)
    function setNativeUpload() {
        if (nativeInputValue.value === base64.value) {
            return;
        }
        originSrc.value = nativeInputValue.value
    }
    function initCropper(image: HTMLImageElement) {

        cropper.value = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 2,
       
        });
        console.log(cropper)
    }



    function onCrop() {

        if (cropper.value) {
            const canvas = cropper.value.getCroppedCanvas()
            const url = canvas.toDataURL("image/png")
            base64.value = url
            originSrc.value = url
            ctx.emit('update:modelValue', url)
        }
        isShowCrop.value = false
    }
    function getObjectURL(file: File) {
        let url = null;
        if ((window as any).createObjectURL != undefined) { // basic
            url = (window as any).createObjectURL(file);
        } else if ((window as any).URL != undefined) { // mozilla(firefox)
            url = (window as any).URL.createObjectURL(file);
        } else if ((window as any).webkitURL != undefined) { // webkit or chrome
            url = (window as any).webkitURL.createObjectURL(file);
        }
        return url;
    }

    function onCancel() {
        if (cropper.value) {
            cropper.value.destroy()
        }
        isShowCrop.value = false
    }


    function onChange(e: any) {
        const file = e.srcElement.files[0]
        base64.value = getObjectURL(file)
        isShowCrop.value = true
        nextTick(() => {
            if (img.value) {
                initCropper(img.value)
            }
        })



    }



    watch(
        () => {
            return nativeInputValue.value;
        },
        () => {
            setNativeUpload();
        }
    );

    onMounted(()=>{
        setNativeUpload();
    })



    return {
        img,
        onCrop,
        base64,
        onChange,
        isShowCrop,
        onCancel,
        originSrc
    }
}

export default useUpload